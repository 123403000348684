import * as React from "react";
import {useRef, useEffect} from 'react';
import Tilt from 'react-parallax-tilt';

import Layout from '../components/layout'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from 'gatsby-plugin-image'

import { parallaxeffectimg, innerelement} from '../components/layout.module.css'



gsap.registerPlugin(ScrollTrigger);

function getPostition(el, height){

    const rect = el.getBoundingClientRect();

    var top = rect.top + window.scrollY;
    
    return top;
    
}

function IndexPage(){
    const catlogo = useRef(null);

    const spanCats = useRef(null);
    const movable0 = useRef(null);
    const movable1 = useRef(null);
    const movable2 = useRef(null);
    const movable3 = useRef(null);
    const movable4 = useRef(null);
    const movable5 = useRef(null);
    const movable6 = useRef(null);

    const scrolling = useRef(null);

    const movable7 = useRef(null);

    const button1 = useRef(null);

    const totarget = useRef(null);

    const div1 = useRef(null);

    const lead1 = useRef(null);
    const lead2 = useRef(null);
    const lead3 = useRef(null);
    const lead4 = useRef(null);

    const lead5 = useRef(null);
    const lead6 = useRef(null);
    const lead7 = useRef(null);
    const lead8 = useRef(null);
    const lead9 = useRef(null);
    const lead10 = useRef(null);
    const lead11 = useRef(null);
    const lead12 = useRef(null);
    const lead13 = useRef(null);
    const lead14 = useRef(null);
    const lead15 = useRef(null);
    const lead16 = useRef(null);
    const lead17 = useRef(null);
    const lead18 = useRef(null);
    const lead19 = useRef(null);
    const lead20 = useRef(null);
    const lead21 = useRef(null);

    //entry animations

    useEffect(() => {
        const cl =  catlogo.current;

        const cS =  spanCats.current;
        const m0 =  movable0.current;
        const m1 =  movable1.current;
        const m2 =  movable2.current;
        const m3 =  movable3.current;
        const m4 =  movable4.current;
        const m5 =  movable5.current;
        const m6 =  movable6.current;
        const m7 =  movable6.current;

        const sl =  scrolling.current;
        const b1 =  button1.current;

        const t1 =  totarget.current;

        const d1 =  div1.current;

        const l1 =  lead1.current;
        const l2 =  lead2.current;
        const l3 =  lead3.current;
        const l4 =  lead4.current;
        const l5 =  lead5.current;
        const l6 =  lead6.current;
        const l7 =  lead7.current;
        const l8 =  lead8.current;
        const l9 =  lead9.current;
        const l10 =  lead10.current;
        const l11 =  lead11.current;
        const l12 =  lead12.current;
        const l13 =  lead13.current;
        const l14 =  lead14.current;
        const l15 =  lead15.current;
        const l16 =  lead16.current;
        const l17 =  lead17.current;
        const l18 =  lead18.current;
        const l19 =  lead19.current;
        const l20 =  lead20.current;
        const l21 =  lead21.current;


        const tl23 = gsap.timeline()
        let tweet = tl23.to(l2,{ innerText: 100, duration: 3, ease: 'ease-out',
                        snap: {
                        innerText:1
                        } 
                        
                        });
                    tl23.from(l6,{opacity:0, x:'50px',duration: 1, ease: 'none'});
                    tl23.from(l12,{opacity:0, x:'-50px',duration: 1, ease: 'none'},4);
                    tl23.from(l7,{opacity:0, x:'50px',duration: 1, ease: 'none'},4);
                    tl23.from(l8,{opacity:0, x:'50px',duration: 1, ease: 'none'},4);
                    tl23.from(l9,{opacity:0, x:'50px',duration: 1, ease: 'none'},4);
                    tl23.from(l10,{opacity:0, x:'-50px',duration: 1, ease: 'none'},4);
                    tl23.from(l11,{opacity:0, x:'-50px',duration: 1, ease: 'none'},4);
                    tl23.from(l13,{opacity:0, x:'-50px',duration: 1, ease: 'none'},4);
                    tl23.from(sl,{opacity:0, x:'-2000px',duration: 1, ease: 'none'},5);
        
        tweet.play();

        const positionOfTarget = getPostition(l14, false);
        const positionOfStart = getPostition(l8, false);
        const tweetvalue = positionOfTarget-positionOfStart;
        const tl2 = gsap.timeline()
        .to(l8,{y: '+=' + tweetvalue, duration: 8, ease: 'none'})
        .to(l6,{opacity:0, duration: 4, ease: 'none'},1)
        .to(l7,{opacity:0, duration: 4, ease: 'none'},1)
        .to(l9,{opacity:0, duration: 4, ease: 'none'},1)
        .to(l10,{opacity:0, duration: 4, ease: 'none'},1)
        .to(l11,{opacity:0, duration: 4, ease: 'none'},1)
        .to(l12,{opacity:0, duration: 4, ease: 'none'},1)
        .to(l13,{opacity:0, duration: 4, ease: 'none'},1)
        
        ScrollTrigger.create({
          animation: tl2,
          trigger: m0,
          start: "top top",
          end: "+=100%", 
          pin: false,
          scrub: true,
          markers: true,
          id:"anim2"
        });

        const tl4 = gsap.timeline()
        .to(cS,{top: 0, left: 0, height:'100vh', width:'100vw', duration: 2, ease: 'none'}, 1)
        .to(l1,{opacity: 1, duration: 2, ease: 'none'}, 3)
        .to(l1,{backgroundImage: 'linear-gradient(0deg, #fff 100%, #BF96E6 200%, #FA87BB 300%, #fff 400%)',  duration: 8, ease: 'none'},3)
        
        
        ScrollTrigger.create({
          animation: tl4,
          trigger: m3,
          start: "top top",
          end: "+=100%", 
          pin: true,
          scrub: true,
          markers: true,
          id:"anim4"
        });


        /* const tl0 = gsap.timeline()
        .to(l1,{backgroundImage: 'linear-gradient(45deg, #fff 100%, #BF96E6 200%, #FA87BB 300%, #fff 400%)',  duration: 8, ease: 'none'})
        
        ScrollTrigger.create({
          animation: tl0,
          trigger: m3,
          start: "top top",
          end: "+=100%", 
          pin: true,
          scrub: true,
          markers: true,
          id:"anim10"
        }); */

        const tl1 = gsap.timeline()
        .to(m2,{backgroundImage: 'linear-gradient(0deg, #fff 100%, #BF96E6 200%, #FA87BB 300%, #fff 400%)',  duration: 8, ease: 'none'})
        
        ScrollTrigger.create({
          animation: tl1,
          trigger: m2,
          start: "top top",
          end: "+=100%", 
          pin: true,
          scrub: true,
          markers: true,
          id:"anim20"
        });

        const tl15 = gsap.timeline()
        
        ScrollTrigger.create({
          animation: tl15,
          trigger: m5,
          start: "top top",
          end: "+=100%", 
          pin: true,
          scrub: true,
          markers: true,
          id:"anim20"
        });

        
        
        

      
      }, [])

    //scrolling the word cat

    
      
    

      
    return (
        <Layout pageTitle="Home Page" > 
            <div className="relative">
                    
                    <div className="flex flex-col items-start h-12 md:h-20 mb-10 md:mb-25 ml-12 md:ml-32 w-full h-screen fixed bottom-0 right-0">
                        <div className="flex flex-row items-center justify-center" ref={scrolling}>
                            <div className="text-[3.5vh] mx-6 font-extralight text-white">keep scrolling</div>  
                            <div>
                                <StaticImage className="h-12 md:h-20 w-12 md:w-20"  alt="" src="../images/Scoll.png"></StaticImage>
                            </div>
                        </div>
                    </div>  
            </div>
            <div className="min-w-full max-w-full bg-gradient-to-t from-purple3 to-pink4" >
                <section ref={movable0} className="flex flex-row justify-left items-center w-full h-screen">
                    
                    <div className="grid grid-cols-12 text-white leading-tight">
                        <div className="col-start-1 col-span-10 flex flex-row pl-10 pt-[1vw]">
                            <h1 className="text-[10vw] md:text-[10vw] font-extralight " ref={lead12}>There are over</h1>
                        </div>
                        <div className="col-start-4 col-span-3 flex flex-row pt-[1vw] justify-end">
                            <h1 className="text-[10vw] md:text-[10vw] font-bold pr-5"ref={lead2}>0</h1>
                            
                        </div>
                        <div className="col-start-7 col-span-5 flex flex-row pt-[1vw]">
                            <h1 className="text-[10vw] md:text-[10vw] font-extralight" ref={lead6}>Million</h1>
                            
                        </div>
                        <div className="col-start-2 col-span-5 flex flex-row pt-[1vw] justify-end">
                            <h1 className="text-[10vw] md:text-[10vw] font-bold pr-5" ref={lead7}>pet</h1>
                            <h1 className="text-[10vw] md:text-[10vw] font-bold pr-5" ref={lead8}>cats</h1>
                        </div>
                        <div className="col-start-7 col-span-4 flex flex-row pt-[1vw] items-center">
                            <h1 className="text-[2vw] md:text-[2vw] font-extralight pl-5" ref={lead9}>and world wide there are even 400 Million cats living with us</h1>
                        </div>
                        <div className="col-start-7 col-span-5 flex flex-row pt-[1vw]">
                            <h1 className="text-[10vw] md:text-[10vw] font-extralight pr-5" ref={lead10}>in</h1>
                            <h1 className="text-[10vw] md:text-[10vw] font-extralight pr-5" ref={lead11}>the</h1>
                            
                            <h1 className="text-[10vw] md:text-[10vw] font-extralight pr-5" ref={lead13}>US</h1> 
                            
                        </div>
                    </div>
                   
                
                </section>   
                <section ref={movable3} className="flex flex-row justify-left items-center w-full">
                    
                    <div className="grid grid-cols-12 text-white leading-tight w-full">
                        <div className="col-start-4 col-span-5 flex flex-row pt-[1vw]">
                            <h1 className="text-[10vw] md:text-[10vw] font-extralight opacity-0" ref={lead14}>cats</h1>
                        </div>
                        <div className="col-start-6 col-span-6 flex flex-row pt-[1vw]">
                            <h1 className="text-[10vw] md:text-[5vw] font-extralight " ref={lead15}>with</h1>
                            <h1 className="text-[10vw] md:text-[5vw] px-5 font-extralight "ref={lead16}>loving</h1>
                            <h1 className="text-[10vw] md:text-[5vw] px-5 font-extralight" ref={lead17}>parents</h1>
                            
                        </div>
                        <div className="col-start-6 col-span-6 flex flex-row pt-[1vw] items-center">
                            <h1 className="text-[10vw] md:text-[5vw] font-extralight" ref={lead18}>that</h1>
                            <h1 className="text-[10vw] md:text-[5vw] px-5 font-extralight" ref={lead19}>celebrate</h1>
                            </div>
                        <div className="col-start-6 col-span-6 flex flex-row pt-[1vw]">
                            <h1 className="text-[10vw] md:text-[5vw] font-bold" ref={lead20}>their</h1>
                            <h1 className="text-[10vw] md:text-[5vw] px-5 font-bold" ref={lead21}>birthdays</h1>
                            
                            
                        </div>
                    </div>
                    <div className="absolute left-[50vw] top-[50vh] w-0 h-0 bg-white self-end mb-12" ref={spanCats}>
                        <div className="flex flex-row items-center leading-tight text-transparent bg-clip-text w-full opacity-0 h-screen" style={{backgroundImage: 'linear-gradient(0deg, #fff -300%, #BF96E6 -200%, #FA87BB -100%, #fff 0%)'}} ref={lead1}>
                            <div className="grid grid-cols-6 md:grid-cols-12 leading-tight w-full">
                                <div className="flex flex-row col-start-2 md:col-start-4 col-span-4 md:col-span-8">
                                    <h1 className="text-[8vw] md:text-[5vw] font-bold " >but did you know...</h1>
                                </div>
                                <div className="flex flex-row col-start-2 md:col-start-4 col-span-4 md:col-span-8">
                                    <h1 className="text-[8vw] md:text-[5vw] font-bold" >that over 30 Million</h1>
                                </div>
                                <div className="flex flex-row col-start-2 md:col-start-4 col-span-4 md:col-span-8" ref={div1}>
                                    <h1 className="text-[8vw] md:text-[5vw] font-bold" ref={lead3}>cats are strays?</h1>
                                </div>
                                <div className="flex flex-row col-start-2 md:col-start-4 col-span-4 md:col-span-8">
                                    <h1 className="text-[8vw] md:text-[5vw] font-bold" ref={lead4}>Which often don't even</h1>
                                </div>
                                <div className="flex flex-row col-start-2 md:col-start-4 col-span-4 md:col-span-8">
                                    <h1 className="text-[8vw] md:text-[5vw] font-bold" ref={lead5}>reach their first birthday?</h1> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                
                </section>   
                
                </div>
                <div className="min-w-full max-w-full bg-gradient-to-t from-purple3 to-pink4" >
                <section ref={movable2} className="flex flex-row justify-left items-center w-full h-screen" style={{backgroundImage: 'linear-gradient(0deg, #fff -300%, #BF96E6 -200%, #FA87BB -100%, #fff 0%)'}} >
                    
                            <div className="grid grid-cols-6 md:grid-cols-12 leading-tight w-full text-white">
                                
                                <div className="flex flex-row col-start-2 md:col-start-2 col-span-4 md:col-span-10 items-center justify-center">
                                    <h1 className="text-[8vw] md:text-[15vw] font-bold" ref={lead5}>What if</h1> 
                                </div>
                            </div>
                           
                        
                </section>
                <section ref={movable5} className="flex flex-row justify-left items-center w-full h-screen" >
                    
                            <div className="grid grid-cols-6 md:grid-cols-12 leading-tight w-full text-white">
                                
                                <div className="flex flex-row col-start-2 md:col-start-2 col-span-4 md:col-span-10 items-center justify-center">
                                    <h1 className="text-[8vw] md:text-[15vw] font-bold" >Cats could help Cats</h1> 
                                </div>
                            </div>
                           
                        
                </section>
                </div>
                
           
     </Layout>
    )
}  
export default IndexPage